@if (isDesktopDevice) {

<mat-toolbar class="appBar">
  <a class="brand" routerLink="/home"
    ><img
      src="../../assets/img/header_small.webp"
      loading="eager"
      alt="logoDetectable"
      class="logo"
      title="Detec'Table"
      routerLink="/home"
  /></a>
  <h1 style="margin-left: 20px">
    {{
      "Le Club des Gourmets "
        | translate : languageService.language() : "The Club for Gourmets"
    }}
  </h1>
  <span class="spacer"></span>

  @if (this.languageService.languageName() === 'Français') {
  <button
    class="switchLangue"
    (click)="this.languageService.switchLanguage('fr')"
  >
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >language_french</mat-icon
    >
  </button>
  } @else {
  <button
    class="switchLangue"
    (click)="this.languageService.switchLanguage('en')"
  >
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >language_gb_english</mat-icon
    >
  </button>
  }

  <mat-icon
    class="material-symbols-outlined"
    aria-label="Menu button"
    style="
      color: #d30050;
      width: 80px;
      height: 33px;
      font-size: 33px;
      cursor: pointer;
    "
    [matMenuTriggerFor]="menu"
    >menu</mat-icon
  >
</mat-toolbar>

} @else if (isTablet) {
<mat-toolbar class="appBar">
  <a class="brand" routerLink="/home"
    ><img
      src="../../assets/img/header_small.webp"
      loading="eager"
      alt="logoDetectable"
      class="logo"
      title="Detec'Table"
      routerLink="/home"
  /></a>
  <h1 style="margin-left: 20px">
    {{
      "Le Club des Gourmets "
        | translate : languageService.language() : "The Club for Gourmets"
    }}
  </h1>
  <span class="spacer"></span>

  @if (this.languageService.languageName() === 'Français') {
  <button
    class="switchLangue"
    (click)="this.languageService.switchLanguage('fr')"
  >
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >language_french</mat-icon
    >
  </button>
  } @else {
  <button
    class="switchLangue"
    (click)="this.languageService.switchLanguage('en')"
  >
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >language_gb_english</mat-icon
    >
  </button>
  }

  <mat-icon
    class="material-symbols-outlined"
    aria-label="Menu button"
    style="
      color: #d30050;
      width: 80px;
      height: 33px;
      font-size: 33px;
      cursor: pointer;
    "
    [matMenuTriggerFor]="menu"
    >menu</mat-icon
  >
</mat-toolbar>
} @else if (isMobile) {
<mat-toolbar class="appBar">
  <a class="brand" routerLink="/home"
    ><img
      src="../../assets/img/avatar-logo.png"
      loading="eager"
      alt="logoDetectable"
      style="width: 50px; height: 45px"
      title="Detec'Table"
      routerLink="/home"
  /></a>
  <h1 style="margin-left: 5px">
    {{
      "Le Club des Gourmets "
        | translate : languageService.language() : "The Club for Gourmets"
    }}
  </h1>
  <span class="spacer"></span>

  @if (this.languageService.languageName() === 'Français') {
  <button
    class="switchLangue"
    aria-label="switchLanguage"
    (click)="this.languageService.switchLanguage('fr')"
  >
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >language_french</mat-icon
    >
  </button>
  } @else {
  <button
    class="switchLangue"
    aria-label="switchLanguage"
    (click)="this.languageService.switchLanguage('en')"
  >
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >language_gb_english</mat-icon
    >
  </button>
  }

  <mat-icon
    class="material-symbols-outlined menu_icon"
    aria-label="Menu button"
    [matMenuTriggerFor]="menu"
    >menu</mat-icon
  >
</mat-toolbar>
}

<mat-menu #menu="matMenu">
  @if (!isLog) {
  <a class="iconMenu" routerLink="/login">
    <mat-icon class="material-symbols-outlined" matListItemIcon>login</mat-icon>
    <span style="margin-left: 10px">{{
      "Connexion" | translate : languageService.language() : "Login"
    }}</span>
  </a>
  <a class="iconMenu" routerLink="/register">
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >app_registration</mat-icon
    >
    <span style="margin-left: 10px">{{
      "Devenir membre"
        | translate : languageService.language() : "Become a member"
    }}</span>
  </a>
  } @if (isLog) {
  <h1
    style="
      width: 200px;
      margin-right: 20px;
      margin-left: 20px;
      color: #000;
      font-size: 20px;
    "
  >
    {{
      "Mon espace membre"
        | translate : languageService.language() : "My member space"
    }}
  </h1>
  <a class="iconMenu" routerLink="/home">
    <mat-icon class="material-symbols-outlined" matListItemIcon>home</mat-icon>
    <span style="margin-left: 10px">{{
      "Accueil" | translate : languageService.language() : "Home"
    }}</span>
  </a>
  <a class="iconMenu" routerLink="/mon-compte">
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >account_circle</mat-icon
    >
    <span style="margin-left: 10px">{{
      "Mon compte" | translate : languageService.language() : "My account"
    }}</span>
  </a>
  <a class="iconMenu" routerLink="/mes-reservations">
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >bookmarks</mat-icon
    >
    <span style="margin-left: 10px">{{
      "Mes réservations"
        | translate : languageService.language() : "My reservations"
    }}</span>
  </a>
  <a class="iconMenu" routerLink="/mes-favoris">
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >favorite</mat-icon
    >
    <span style="margin-left: 10px">{{
      "Mes favoris" | translate : languageService.language() : "My favourites"
    }}</span>
  </a>
  <a class="iconMenu" routerLink="/mes-recommandations">
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >thumb_up</mat-icon
    >
    <span style="margin-left: 10px">{{
      "Mes Recommandations"
        | translate : languageService.language() : "My recommendations"
    }}</span>
  </a>
  <a class="iconMenu" (click)="logOut()">
    <mat-icon class="material-symbols-outlined" matListItemIcon
      >logout</mat-icon
    >
    <span style="margin-left: 10px">{{
      "Déconnexion" | translate : languageService.language() : "Logout"
    }}</span>
  </a>
  }
</mat-menu>
