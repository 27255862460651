import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  ElementRef,
  OnInit,
  inject,
  viewChild
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { translate } from '@pipes/transalte.pipe';
import { AuthService } from '../../services/auth.service';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { Auth } from '@angular/fire/auth';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { LanguageService } from '@services/language.service';

@Component({
    selector: 'app-header',
    providers: [],
    imports: [
        CommonModule,
        MatIcon,
        MatToolbarModule,
        MatMenuModule,
        MatIcon,
        MatButtonModule,
        translate,
        MatDividerModule,
        RouterLink,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit {

  isLog: boolean = false;
  userUID: string = '';
  isMobile: boolean = false;
  isTablet: boolean = false;
  isDesktopDevice: boolean = false;

  readonly searchCity = viewChild.required<ElementRef>('searchCity');
  city: string = '';

  private authService = inject(AuthService);
  private auth = inject(Auth);
  private breakpointObserver = inject(BreakpointObserver);
  languageService = inject(LanguageService);

  constructor() {

    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
        this.isTablet = false;
        this.isDesktopDevice = false;
      }
    });
    this.breakpointObserver.observe([Breakpoints.Tablet]).subscribe((result) => {
      if (result.matches) {
        this.isMobile = false;
        this.isTablet = true;
        this.isDesktopDevice = false;
      }
    });
    this.breakpointObserver
      .observe([Breakpoints.Large, Breakpoints.XLarge])
      .subscribe((result) => {
        if (result.matches) {
          this.isMobile = false;
          this.isTablet = false;
          this.isDesktopDevice = true;
        }
      });
  }

  ngOnInit(): void {
    this.auth.onAuthStateChanged((user: any) => {
      if (user) {
        this.isLog = true;
        this.userUID = user.uid;
      } else {
        this.isLog = false;
      }
    });
  }

  logOut() {
    this.authService.logOutUser();
  }
}
